<template>
	<div>
		<div v-if="needData" style="margin-top: 20px;">
			
			<div v-for="item in fileList" :key="item.name" style="width: 90%;margin: 0 auto;">
				<div style="display: flex; justify-content: flex-start; align-items: center;margin-bottom: 10px;border-bottom: 2px solid #4392F4;padding-bottom: 10px">
					<div style="font-size: 24px;font-weight: bold;">{{ item.name }}</div>
					<el-popover
							placement="right"
							width="500"
							trigger="click"
							popper-class="popperClass"
							v-if="item.name !== '灭火器'&&item.name !== '其他'"
					>
						<div style="font-size: 22px">{{ item.remark }}</div>
						<el-button slot="reference"  class="circle-button" type="text" @click="">？</el-button>
					</el-popover>
				</div>
				<div v-for="ite in item.data" :key="ite.title" >
					<div class="itemList">
						<div style="font-size: 18px;padding-left: 10px"><span style="color: red;">{{ite.isXing?'*':undefined}}</span>{{ ite.title }}</div>
						<!--					<el-button style="margin-right: 20px" type="primary" size="mini" @click="addSheet(ite)">{{ ite.button }}</el-button>-->
<!--						<input type="file" v-if="!isDisabled" style='font-size: 18px;width: 30%;' @change="handleFileUpload($event, ite,item)"  multiple/> &lt;!&ndash; 文件选择控件，传递额外参数 &ndash;&gt;-->
					</div>
					<div v-for="child in ite.childFiles" class="fileListItem">
						<div>
<!--							<el-button type="text" size="mini" @click="showFile(child) ">{{ child.fileName }}</el-button>-->
							<el-button type="text" size="mini" @click="downloadDrawing(child) ">{{ child.fileName }}</el-button>
						</div>
						<div>
							<el-button type="text" size="mini" @click="downloadDrawing(child)">浏览</el-button>
<!--							<el-button type="text" v-if="!isDisabled" size="mini" @click="deleteDrawing(child)">删除</el-button>-->
						</div>
					</div>
				</div>
			</div>
			<el-dialog
					title="图纸预览"
					:visible.sync="drawingDialog"
					v-model="drawingDialog"
					:modal="false"
					:append-to-body="true"
					width='60%'
					:fullscreen="true"
			>
				<iframe
						style="width: 100%;height: 100vh"
						:src="drawingUrl"
				></iframe>
			</el-dialog>
			<el-dialog
					title="上传图纸"
					:visible.sync="drawingDialog"
					v-model="drawingDialog"
					:modal="false"
					:append-to-body="true"
					width='60%'
					:fullscreen="true"
			>
				<iframe
						style="width: 100%;height: 100vh"
						:src="drawingUrl"
				></iframe>
			</el-dialog>
		</div>
		<div v-else style="width: 100%;margin: 10% auto;">
			<div style="text-align: center">
				{{'请先填写申报表后在添加图纸！'}}
			</div>
		</div>
	</div>
</template>


<script>
import util from "../../../util/date";
// import ObsClient from "../../lib/obs";
import WordTable from "./wordTable";
import {
	getProjectFileInfo,
	addProjectFile,
	deleteProjectFile,
	generateTemporaryUrl,
	getApplicationByProjectId, getKYJCApplicationByProjectId, generateTemporaryUrlByFileName
} from '../../api/api'
// import ObsClient from '../../../util/ esdk-obs-browserjs-without-polyfill.3.22.3.min'
// @ts-ignore
import ObsClient from 'esdk-obs-browserjs/src/obs'



export default {
	components:{},
	data() {
		return {
			url:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
			drawingDialog:false,
			fileInfo:[],
			defaultProps: {
				children: 'childFiles',
				label: 'fileName',
			},
			fileList:[
				{
					name: '场所平面结构图',
					remark:'包括场地四邻原有及规划道路的位置和主要建筑物及构筑物的位置、名称、功能、层数、间距;场所所在建筑物的位置、名称、层数和消防车道及登高操作场地布置等内容；包括场所平面布置，房间功能和面积，疏散楼梯、疏散走道，消防电梯，平面或空间的防火、防烟分区面积、分隔位置和分隔物；各部位(顶棚、墙面、地面、隔断的装修材料以及固定家具、装饰织物、其他装饰等)装修材料燃烧性能等内容。',
					data:[
						{
							title:'消防设计说明（图纸设计说明以合并方式书写的可重复上传）',
							id:20,
							isXing:false,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'总平面图',
							isXing:false,
							id:1,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'楼层平面布置图',
							isXing:false,
							id:2,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'场所平面布置图',
							isXing:false,
							id:28,
							button:'上传图纸',
							childFiles:[],
							drawingUrl:'https://qdzn-pro.obs.cn-east-3.myhuaweicloud.com/docs/%E5%9B%BE%E7%BA%B8%E6%A0%B7%E4%BE%8B/20241113/%E6%A5%BC%E5%B1%82%E5%B9%B3%E9%9D%A2%E5%B8%83%E7%BD%AE%E5%9B%BE/%E5%B9%B3%E9%9D%A2%E5%B8%83%E7%BD%AE%E5%9B%BE.pdf',
						},
						{
							title:'天花装修平面图',
							isXing:false,
							id:25,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'墙面装修平面图',
							isXing:false,
							id:27,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'地面装修平面图',
							isXing:false,
							id:26,
							button:'上传图纸',
							childFiles:[],
						},
					]
				},
				{
					name: '消防电源及配电',
					remark:'包括消防供电负荷等级，消防配电线路的敷设方式，备用消防电源的供电时间和容量；包括消防应急照明和疏散指示的系统方式，配电方式及供电时间，灯具选型，照度、安装位置和高度，线路敷设方式等。',
					data:[
						{
							title:'消防电气设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:21,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防配电平面图、系统图',
							isXing:false,
							id:3,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'应急照明和疏散指示平面图、系统图',
							isXing:false,
							id:4,
							button:'上传图纸',
							childFiles:[],
						}
					]
				},
				{
					name: '火灾自动报警系统',
					remark:'包括系统形式，线路的敷设方式，火灾探测器的类型及位置，手动报警按钮、消火栓启泵按钮、消防电话、火灾应急广播及消防联动控制设备位置等内容。',
					data:[
						{
							title:'消防电气设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:22,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防控制室位置平面图',
							isXing:false,
							id:5,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'火灾自动报警系统图、各层报警平面图',
							isXing:false,
							id:6,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'可燃气体探测报警系统平面图、系统图',
							isXing:false,
							id:7,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'电气火灾监控系统平面图、系统图',
							isXing:false,
							id:8,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'防火门监控系统平面图、系统图',
							isXing:false,
							id:9,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防设备电源监控系统图',
							isXing:false,
							id:10,
							button:'上传图纸',
							childFiles:[],
						},
					]
				},
				{
					name: '消防给水和灭火设施',
					remark:'包括消防水源的供水方式，消防水泵房、消防水池、水泵接合器位置，室外消防给水系统给水管道及室外消火布置；包括室内消火栓系统给水管道及消火栓的布置；包括系统组成、控制方式，报警阀、末端试水装置位置；包括系统组成、控制方式，储瓶间位置',
					data:[
						{
							title:'消防给排水设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:23,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防给水总平面图，各消防给水系统的平面图、系统图',
							isXing:false,
							id:11,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防水池和消防水泵房平面图',
							isXing:false,
							id:12,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'室内消火栓系统图、各层平面图',
							isXing:false,
							id:13,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'自动喷水灭火系统的平面图、系统图',
							isXing:false,
							id:14,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'气体灭火系统的平面图、系统图',
							isXing:false,
							id:15,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'灭火器配置平面',
							isXing:false,
							id:18,
							button:'上传图纸',
							childFiles:[],
						},],
				},
				{
					name: '防烟排烟及供暖、通风和空气调节设施',
					remark:'包括设置防烟的区域及方式，送风口以及防火阀的位置；供暖、通风和空气调节设施的防火措施；包括设置排烟的区域及方式，送风口、排烟口以及防火阀、排烟防火阀的位置；供暖、通风和空气调节设施的防火措施。',
					data:[
						{
							title:'消防防排烟、暖通设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:24,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'防烟系统平面图、系统图（防、排烟系统以合并方式作图的可重复上传）',
							isXing:false,
							id:16,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'排烟系统平面图、系统图（防、排烟系统以合并方式作图的可重复上传）',
							isXing:false,
							id:17,
							button:'上传图纸',
							childFiles:[],
						}
					]
				},
				{
					name: '其他',
					remark:'包括系统组成、控制方式',
					data:[
						
						{
							title:'其他',
							isXing:false,
							id:19,
							button:'上传图纸',
							childFiles:[],
						},
					]
				},
			],
			Bucket: process.env.VUE_APP_BUCKET_NAME,  // 存储桶名称
			obsClient: null,  // 初始化OBS客户端
			uploading: false, // 上传状态
			Area:  process.env.VUE_APP_SERVER_ACCESS_AREA_A,    // 区域名称
			keyValue:'',
			drawingUrl:'',
			drawingList:[],
			vLoading:false,
			placeJsonData:[],
		}
	},
	props:['needData','isDisabled'],
	methods: {
		clearFileInput(inputELement){
			inputELement.value = null;
		},
		cadExport(){
			window.open('https://qdzn-pro.obs.cn-east-3.myhuaweicloud.com/docs/CAD%E5%9B%BE%E7%BA%B8%E5%AF%BC%E5%87%BAPDF%E6%A0%BC%E5%BC%8F%E6%93%8D%E4%BD%9C%E8%AF%B4%E6%98%8E.mp4');
		},
		//下载图纸
		downloadDrawing(child){
			// console.log(child,'chileddddddd')
			let params = {
				fileName:child.filePath.split('com/')[1],
				// projectId:this.needData.id,
			}
			generateTemporaryUrlByFileName(params).then(res=>{
					// console.log(res,'res2222222')
					window.open(res.data);
			})
		},
		//删除图纸
		deleteDrawing(child){
			// console.log(child,'chileddddddd')
			let params = {
				fileId:child.id,
				projectId:this.needData.id,
			}
			deleteProjectFile(params).then(res=>{
				if(res.data.success){
					this.$message({
						type: 'success',
						message: res.data.message,
					});
					this.getProjectFile();
				}else{
					this.$message({
						type: 'error',
						message: res.data.message,
					});
				}
			})
		},
		//添加图纸到childFile
		updateChildFileById(files, file,Key,additionalData) {
			let params = {
				fileName: file.name.split('.pdf')[0],
				filePath: Key,
				fileTag: additionalData.id,
				// folderId: [1, 2, 19].includes(additionalData.id) ?
				//     this.fileInfo[0].childFiles[0].childFiles[0].folderId+1 : this.fileInfo[0].childFiles[0].childFiles[0].folderId+2,
				projectId: this.needData.id,
				stageId: this.fileInfo[0].stageId,
			};
			if ( [21,3,4].includes(additionalData.id)){
				params.folderId = this.fileInfo[0].childFiles[0].childFiles[0].folderId+2
			}
			if ( [22,5,6,7,8,9,10].includes(additionalData.id)){
				params.folderId = this.fileInfo[0].childFiles[0].childFiles[0].folderId+3
			}
			if ( [23,11,12,13,14,15,18].includes(additionalData.id)){
				params.folderId = this.fileInfo[0].childFiles[0].childFiles[0].folderId+4
			}
			if ( [24,16,17].includes(additionalData.id)){
				params.folderId = this.fileInfo[0].childFiles[0].childFiles[0].folderId+5
			}
			if ( [19,1,2,20].includes(additionalData.id)){
				params.folderId = this.fileInfo[0].childFiles[0].childFiles[0].folderId+1
			}
			
			// Call addProjectFile with the params
			addProjectFile(params).then(res => {
				if (res.data.success) {
					
					// this.vLoading = false;
					if (files[files.length-1] === file){
						this.$message({
							type: 'success',
							message: res.data.message,
						});
						const fileInput = document.querySelector('input[type="file"]');
						this.clearFileInput(fileInput);
						setTimeout(() => {
							this.getProjectFile();
						},100)
					}
					
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					});
				}
			});
			
		},
		showFile (file) {
			this.drawingUrl = file.filePathDxf;
			this.drawingDialog = true;
		},
		// 处理文件选择
		handleFileUpload(event, additionalData,item) {
			// console.log(event,'ebeekljedklfjsdkf')
			const files = [...event.target.files]; // 获取选择的文件
			files.forEach(file => {
				setTimeout(() => {
					if (file) {
						// console.log(file, 'file')
						if (!this.vLoading) {
							// this.vLoading = true;
							if (file) {
								if (additionalData.id === 1 || additionalData.id === 2 || additionalData.id === 19) {
									const Key = 'projectFile' + '/' + this.needData.projectName + this.needData.projectNo + '/' + this.fileInfo[0].stageId + '/' + this.fileInfo[0].childFiles[0].fileName + '/' +
											this.fileInfo[0].childFiles[0].childFiles[0].fileName + '/' + file.name; // 使用文件名作为上传的Key
									this.uploadFile(files,file, Key, additionalData); // 调用上传方法，并传递额外参数 this.uploadFile(file, Key, additionalData); // 调用上传方法，并传递额外参数
									// console.log(Key, 'Key')
								} else {
									const Key = 'projectFile' + '/' + this.needData.projectName + this.needData.projectNo + '/' + this.fileInfo[0].stageId + '/' + this.fileInfo[0].childFiles[0].fileName + '/' +
											this.fileInfo[0].childFiles[0].childFiles[0].fileName + '/' + item.name + '/' + file.name; // 使用文件名作为上传的Key
									// console.log(Key, 'Key')
									this.uploadFile(files,file, Key, additionalData); // 调用上传方法，并传递额外参数 this.uploadFile(file, Key, additionalData); // 调用上传方法，并传递额外参数
								}
							}
							
						}
					}
				},0)
				
			});
		},
		// 上传文件方法
		uploadFile(files, file, Key, additionalData) {
			const loading = this.$loading({
				lock: true,
				text: '上传中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			this.obsClient = new ObsClient({
				access_key_id: process.env.VUE_APP_ACCESS_KEY_ID,
				secret_access_key: process.env.VUE_APP_SECRET_ACCESS_KEY,
				server: process.env.VUE_APP_SERVER_ACCESS_AREA
			});
			// console.log(this.obsClient, 'obsClient');
			this.uploading = true;
			// console.log('额外参数:', additionalData);
			this.obsClient.putObject({
				Bucket: this.Bucket,
				Key,
				SourceFile: file,
				ContentType: 'application/pdf',
			}, (err, result) => {
				this.uploading = false;
				
				if (err) {
					loading.close();
					console.error('上传出错:', err);
				} else {
					// console.log('上传成功:', result);
					this.vLoading = false;
					if (files[files.length-1] === file){
						loading.close();
					}
					// Check if fileInfo and needData are defined
					if (this.fileInfo && this.fileInfo.length > 0 && this.needData) {
						this.updateChildFileById(files, file, Key, additionalData);
					} else {
						console.error('必要的数据未定义：fileInfo 或 needData');
					}
					const url = `https://${this.Bucket}.obs.${this.Area}/${Key}`;
					// console.log('文件URL:', url);
				}
			});
			
		},
		
		
		//添加图纸
		addSheet( row ){
			this.fileList.forEach(item => {
				item.data.forEach(dataItem => {
					if (dataItem.id === row.fileTag) {
						dataItem.childFiles.push( {...row });
					}
				});
			})
		},
		addDrawing(items) {
			this.drawingList = [];
			const filteredItems = []; // 新数组，用于存放对象
			const processItems = (items) => {
				items.forEach(item => {
					if (item.filePathDxf !== null) {
						filteredItems.push(item);
					}
					if (item.childFiles && item.childFiles.length > 0) {
						processItems(item.childFiles);
					}
				});
			};
			processItems(items);
			// 你可以根据需求使用 filteredItems 数组，例如添加到 drawingList 中
			this.drawingList.push(...filteredItems);
		},
		
		//获取申报表
		getapplication () {
			if (this.needData){
				let params = {
					projectId: this.needData.id
				}
				getKYJCApplicationByProjectId(params).then(res => {
					let result = res.data
					if (result.success) {
						let resp = result.response
						const res=resp.projectFireFacilitiesIds.split(',')
						this.placeJsonData=res.map(str => parseInt(str, 10));
						this.placeJsonData.forEach(item => {
							this.addSheets(item)
						})
						// console.log(this.placeJsonData,'resp')
					}
				})
			}
		},
		//添加图纸
		addSheets( row ){
			this.fileList.forEach(item => {
				item.data.forEach(dataItem => {
					if (dataItem.id === row) {
						dataItem.isXing = true;
						if(row===19){
							dataItem.isXing = false;
						}
					}
				});
			})
		},
		//获取项目文件结构
		getProjectFile(){
			this.fileList = [
				{
					name: '场所平面结构图',
					remark:'包括场地四邻原有及规划道路的位置和主要建筑物及构筑物的位置、名称、功能、层数、间距;场所所在建筑物的位置、名称、层数和消防车道及登高操作场地布置等内容；包括场所平面布置，房间功能和面积，疏散楼梯、疏散走道，消防电梯，平面或空间的防火、防烟分区面积、分隔位置和分隔物；各部位(顶棚、墙面、地面、隔断的装修材料以及固定家具、装饰织物、其他装饰等)装修材料燃烧性能等内容。',
					data:[
						{
							title:'消防设计说明（图纸设计说明以合并方式书写的可重复上传）',
							id:20,
							isXing:false,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'总平面图',
							isXing:false,
							id:1,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'楼层平面布置图',
							isXing:false,
							id:2,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'场所平面布置图',
							isXing:false,
							id:28,
							button:'上传图纸',
							childFiles:[],
							drawingUrl:'https://qdzn-pro.obs.cn-east-3.myhuaweicloud.com/docs/%E5%9B%BE%E7%BA%B8%E6%A0%B7%E4%BE%8B/20241113/%E6%A5%BC%E5%B1%82%E5%B9%B3%E9%9D%A2%E5%B8%83%E7%BD%AE%E5%9B%BE/%E5%B9%B3%E9%9D%A2%E5%B8%83%E7%BD%AE%E5%9B%BE.pdf',
						},
						{
							title:'天花装修平面图',
							isXing:false,
							id:25,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'墙面装修平面图',
							isXing:false,
							id:27,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'地面装修平面图',
							isXing:false,
							id:26,
							button:'上传图纸',
							childFiles:[],
						},
					]
				},
				{
					name: '消防电源及配电',
					remark:'包括消防供电负荷等级，消防配电线路的敷设方式，备用消防电源的供电时间和容量；包括消防应急照明和疏散指示的系统方式，配电方式及供电时间，灯具选型，照度、安装位置和高度，线路敷设方式等。',
					data:[
						{
							title:'消防电气设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:21,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防配电平面图、系统图',
							isXing:false,
							id:3,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'应急照明和疏散指示平面图、系统图',
							isXing:false,
							id:4,
							button:'上传图纸',
							childFiles:[],
						}
					]
				},
				{
					name: '火灾自动报警系统',
					remark:'包括系统形式，线路的敷设方式，火灾探测器的类型及位置，手动报警按钮、消火栓启泵按钮、消防电话、火灾应急广播及消防联动控制设备位置等内容。',
					data:[
						{
							title:'消防电气设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:22,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防控制室位置平面图',
							isXing:false,
							id:5,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'火灾自动报警系统图、各层报警平面图',
							isXing:false,
							id:6,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'可燃气体探测报警系统平面图、系统图',
							isXing:false,
							id:7,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'电气火灾监控系统平面图、系统图',
							isXing:false,
							id:8,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'防火门监控系统平面图、系统图',
							isXing:false,
							id:9,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防设备电源监控系统图',
							isXing:false,
							id:10,
							button:'上传图纸',
							childFiles:[],
						},
					]
				},
				{
					name: '消防给水和灭火设施',
					remark:'包括消防水源的供水方式，消防水泵房、消防水池、水泵接合器位置，室外消防给水系统给水管道及室外消火布置；包括室内消火栓系统给水管道及消火栓的布置；包括系统组成、控制方式，报警阀、末端试水装置位置；包括系统组成、控制方式，储瓶间位置',
					data:[
						{
							title:'消防给排水设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:23,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防给水总平面图，各消防给水系统的平面图、系统图',
							isXing:false,
							id:11,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'消防水池和消防水泵房平面图',
							isXing:false,
							id:12,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'室内消火栓系统图、各层平面图',
							isXing:false,
							id:13,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'自动喷水灭火系统的平面图、系统图',
							isXing:false,
							id:14,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'气体灭火系统的平面图、系统图',
							isXing:false,
							id:15,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'灭火器配置平面',
							isXing:false,
							id:18,
							button:'上传图纸',
							childFiles:[],
						},],
				},
				{
					name: '防烟排烟及供暖、通风和空气调节设施',
					remark:'包括设置防烟的区域及方式，送风口以及防火阀的位置；供暖、通风和空气调节设施的防火措施；包括设置排烟的区域及方式，送风口、排烟口以及防火阀、排烟防火阀的位置；供暖、通风和空气调节设施的防火措施。',
					data:[
						{
							title:'消防防排烟、暖通设计说明（图纸设计说明以合并方式书写的可重复上传）',
							isXing:false,
							id:24,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'防烟系统平面图、系统图（防、排烟系统以合并方式作图的可重复上传）',
							isXing:false,
							id:16,
							button:'上传图纸',
							childFiles:[],
						},
						{
							title:'排烟系统平面图、系统图（防、排烟系统以合并方式作图的可重复上传）',
							isXing:false,
							id:17,
							button:'上传图纸',
							childFiles:[],
						}
					]
				},
				{
					name: '其他',
					remark:'包括系统组成、控制方式',
					data:[
						
						{
							title:'其他',
							isXing:false,
							id:19,
							button:'上传图纸',
							childFiles:[],
						},
					]
				},
			]
			this.getapplication()
			let params = {
				projectId: this.needData.id
			}
			getProjectFileInfo(params).then(res => {
				let result = res.data
				if(result.success) {
					this.fileInfo =[ result.response.files[result.response.files.length-1]]
					console.log(this.fileInfo,'文件信息')
					this.addDrawing(this.fileInfo)
					this.drawingList.forEach(item => {
						this.addSheet(item)
					})
					console.log(this.fileList,'fileList')
				}
			})
			
		},
	},
	watch: {
		'needData': function(val, oldVal) {
			this.vLoading = false;
			this.getProjectFile()
		}
	},
	created(){
		this.getProjectFile()
		
	},
	mounted() {
	
	
	}
}
</script>

<style lang="stylus" scoped>
.fileListItem{
	align-items:center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-top :10px;
	padding :5px 20px
	border: 1px solid #ccc;
}
.popperClass{
	background-color: #b9d5f5;
}
.el-popover{
	padding :0
	background-color :#b9d5f5;
}

.itemList{
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	background-color: #dcdbdb;
	align-items: center;
	height: 40px;
}
.circle-button {
	font-weight: bold;
	font-size: 18px;
	background-color: #b9d5f5;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 8px;
	padding-bottom :8px
	margin-top :5px
}
.timerSty {
	width: 100px;
}
.noTimerSty {
	margin-left: 20px;
}
.areaSty {
	width: 300px;
	margin-right: 2px;
}
.dialogTitle {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}
.activeSty {
	margin-top: 10px;
}
.activeSty .el-form-item {
	margin-bottom: 0px;
}
.titleSpeSty {
	font-weight: bold;
}
.spacilTimer>>>.el-form-item__label {
	color: red!important;
	font-weight: bold;
}

.project-notes {
	white-space: pre-wrap; /* 保留换行符并允许文本自动换行 */
	word-wrap: break-word; /* 在长单词或URL换行 */
}
</style>

<template xmlns="http://www.w3.org/1999/html">
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="关键字:">
            <el-input v-model="filters.keyword" @input="(e) => (filters.keyword = utils.validForbid(e))"
                      placeholder="条文编号/条文内容" clearable></el-input>
          </el-form-item>
          <el-form-item label="所属规范:">
            <el-select v-model="filters.standardId" style="width: 424px" clearable filterable remote>
              <el-option v-for="item in filteredStandardOptions" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table :data="tableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
              @current-change='currentChange' :highlight-current-row='true'>
      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="bn" label="条文编号" align="center" width="85"></el-table-column>
      <el-table-column prop="standardName" label="所属规范" align="center" width="430">
        <template slot-scope="scope">
          <!-- 规范名称+空格+规范编号+（版本号+年版） -->
          <span v-if="scope.row.standardVersion">
                        {{
              scope.row.standardName + ' ' + scope.row.standardBn + '（' + scope.row.standardVersion + '）'
            }}
                    </span>
          <span v-else>
                        {{ scope.row.standardName + ' ' + scope.row.standardBn }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="条文内容" align="left">
        <!-- 条文内容显示 条文编号 + 第一款的内容 + ... -->
        <template slot-scope="scope">
                    <span v-if="scope.row.content[0].children.length > 0">
                        {{ scope.row.content[0].bn + ' ' + scope.row.content[0].children[0].content + '...' }}
                    </span>
          <span v-else-if="scope.row.content[0].children.length === 0">
                        {{ scope.row.content[0].bn }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button @click="onReview(scope.row.id)" type="text" size="small">
            预览
          </el-button>
          <el-button type="text" size="small" @click="handleAddMore">添加问题</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
      </el-pagination>
    </el-col>
    <!-- 新建弹窗 -->
    <el-dialog :title="dialog.add.title" :visible.sync="dialog.add.show" v-model="dialog.add.show"
               :close-on-click-modal="false" width='65%'>
      <div v-if="step === 1">
        <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px"
                 style="min-width:800px">
          <el-form-item label="新增条:" prop="bn">
            <el-input style="width:200px" v-model="addForm.bn" clearable></el-input>
          </el-form-item>
          <el-form-item label="所属规范:" prop="standardId">
            <el-select v-model="addForm.standardId" style="width: 424px" clearable filterable remote
                       filterable>
              <el-option v-for="item in filteredStandardOptions" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="step === 2">
        <el-form v-if="dialog.add.editing" :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px"
                 style='min-width:800px'>
          <el-form-item label="条文编号:" prop="bn">
            <el-input style="width:200px" v-model="addForm.bn" clearable></el-input>
          </el-form-item>
          <el-form-item label="所属规范:" prop="standardId" >
            <el-select v-model="addForm.standardId" clearable style="width: 100%;" filterable>
              <el-option v-for="item in standardOptions" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form label-width="160px" style='min-width:800px'>
          <el-form-item label="" prop="">
            <el-tree style="margin-bottom:50px" :data="standardTreedata" node-key="id" default-expand-all
                     :expand-on-click-node="false" >
                            <span @click="checkNode(node, data)" class="custom-tree-node" slot-scope="{ node, data }">
                                <span>{{ node.data.bn }}</span>
                              <!-- <span v-if="node.level === 1">{{ node.data.bn }}条</span>
                              <span v-else-if="node.level === 2">第{{ node.data.bn }}款</span>
                              <span v-else-if="node.level === 3">第{{ node.data.bn }}项</span>
                              <span v-else-if="node.level === 4">第{{ node.data.bn }}目</span> -->
                                <span>
                                    <el-button
                                        type="text"
                                        :disabled="node.level === 4"
                                        size="mini"
                                        @click.stop="() => addChild(node, data)"
                                    >
                                        新增子节点
                                    </el-button>
                                    <el-button
                                        type="text"
                                        :disabled="node.level === 1"
                                        size="mini"
                                        @click.stop="() => delItem(node, data)"
                                    >
                                        删除节点
                                    </el-button>
                                  <el-button
                                      type="text"
                                      size="mini"
                                      @click.stop="() => onNotesMore(node,data)"
                                  >
                                        条文关联注
                                    </el-button>
                                </span>
                            </span>
            </el-tree>
          </el-form-item>
        </el-form>
        <el-form v-if="!checkedTop" :model="form" ref="formRef" label-width="160px" style='min-width:800px'>
          <el-form-item label="编号:" prop="bn">
            <el-input style="width:200px" v-model="form.bn"></el-input>
          </el-form-item>
<!--          <el-form-item label="关联图注:" prop="content">-->
<!--            <el-button-->
<!--                type="primary"-->
<!--                size="mini"-->
<!--                @click.stop="() => onNotesMore()"-->
<!--            >添加条文关联注</el-button>-->
<!--          </el-form-item>-->
          <el-form-item label="条文内容:" prop="content">
            <el-input type="textarea" :rows="4" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="条文类型:" prop="typeId">
            <el-select v-model="form.typeId">
              <el-option v-for="item in typeOptions" :key="item.id" :value="item.id"
                         :label="item.value"></el-option>
            </el-select>
          </el-form-item>
	        <el-form-item label="AI:">
		        <template>
			        <el-radio-group v-model="form.isAi">
				        <el-radio  :label="true">是</el-radio>
				        <el-radio  :label="false">否</el-radio>
			        </el-radio-group>
		        </template>
	        </el-form-item>
          <el-form-item>
            <el-button @click="saveRuleSubmit" type="primary">保存子节点</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="step === 1" type="primary" @click="nextStep"
                   :loading="dialog.add.loading">下一步
        </el-button>
        <el-button v-else-if="step === 2 && dialog.add.editing" type="primary" @click="nextStep"
                   :loading="dialog.add.loading">确认
        </el-button>
        <el-button @click.native="addDialogClose">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 条纹规则弹窗 -->
    <el-dialog :title="dialog.rule.title" :visible.sync="dialog.rule.show" v-model="dialog.rule.show"
               :close-on-click-modal="false" width='50%'>
      <el-form :model="form" :rules="rules" ref="formRef" label-width="160px" style='"min-width"="800px"'>
        <el-form-item label="编号:" prop="bn">
          <el-input v-model="form.bn"></el-input>
        </el-form-item>
        <el-form-item label="条文内容:" prop="content">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="条文类型:" prop="typeId">
          <el-select v-model="form.typeId">
            <el-option v-for="item in typeOptions" :key="item.id" :value="item.id"
                       :label="item.value"></el-option>
          </el-select>
        </el-form-item>
	      <el-form-item label="AI:" required>
		      <template>
			      <el-radio-group v-model="form.isAi">
				      <el-radio  :label="true">是</el-radio>
				      <el-radio  :label="false">否</el-radio>
			      </el-radio-group>
		      </template>
	      </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="ruleDialogClose">取消</el-button>
        <el-button type="primary" @click="addRuleSubmit" :loading="dialog.rule.loading">确认</el-button>
      </div>
    </el-dialog>
    <!-- 关联注 -->
    <el-dialog title="关联图注" :visible.sync="dialog.note.show" v-model="dialog.note.show"
               :close-on-click-modal="false" width='50%'>
      <div class="dialog-content">
        <div class="lf">
          <el-tree :data="standardTreedata" node-key="id" default-expand-all :expand-on-click-node="false"
                   @node-click='checkNote'>
                        <span slot-scope="{ node }">
                            <span>{{ node.data.bn }}</span>
                          <!-- <span v-if="node.level === 1">{{ node.data.bn }}条</span>
                          <span v-else-if="node.level === 2">第{{ node.data.bn }}款</span>
                          <span v-else-if="node.level === 3">第{{ node.data.bn }}项</span>
                          <span v-else-if="node.level === 4">第{{ node.data.bn }}目</span> -->
                            <span>
                                <el-button type="text" size="mini">
                                    查看图注
                                </el-button>
                            </span>
                        </span>
          </el-tree>
        </div>
        <div class="rt">
          <h3 style="margin-bottom:20px">{{ dialog.note.title }}</h3>
          <el-button size="mini" @click="addNotes">添加图注</el-button>
          <div v-if="notesList.length > 0">
            <el-card style="text-align:left;margin-top: 20px;margin-bottom: 20px;width:90%"
                     v-for="(item, index) in notesList" :key="index">
              <div slot="header" class="clearfix">
                <span>{{ item.bn }}{{ item.title }}</span>
                <el-button @click="delNotes(item)" style="float: right; padding: 3px 0"
                           type="text">删除
                </el-button>
                <el-button @click="editNote(item)" style="float: right; margin-right:10px; padding: 3px 0"
                           type="text">编辑
                </el-button>
              </div>
              <div class="picList">
                <img @click="handlePictureCardPreview(pic)" v-for="(pic, picindex) in item.picList"
                     :key="picindex" :src="pic" style="width:120px;height:80px"/>
              </div>
              <div v-for="(i, indexs) in item.content" :key="indexs">
                <span v-if="indexs === 0">注</span>
                {{ i.bn + i.content }}
              </div>
            </el-card>
          </div>
          <div v-else>暂无关联图注</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="noteDialogClose">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 新增注 -->
    <el-dialog :title="dialog.addNote.isEdit?'编辑图注':'新增图注'" :visible.sync="dialog.addNote.show"
               v-model="dialog.addNote.show" :close-on-click-modal="false" width='50%'>
      <div>
        <el-form :model="noteForm" :rules="noteRules" ref="noteRef" label-width="100px" style="width: 100%;">
          <el-form-item>
            <el-checkbox v-model="unNeed.pic" :checked="unNeed.pic">无需上传图片</el-checkbox>
          </el-form-item>
          <el-form-item v-if="!unNeed.pic" label="编号" prop="bn">
            <el-input v-model="noteForm.bn"></el-input>
          </el-form-item>
          <el-form-item v-if="!unNeed.pic" label="图名" prop="title">
            <el-input v-model="noteForm.title"></el-input>
          </el-form-item>
          <el-form-item v-if="!unNeed.pic" label="图片：" prop="picList">
            <el-upload ref='upload' :action="actions" :headers='headers' :file-list="fileList"
                       list-type="picture-card" :on-success='uploadSuccess' :on-change='handleChange'
                       :on-preview="handlePictureCardPreview">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="unNeed.content" :checked="unNeed.content">无需上传注</el-checkbox>
          </el-form-item>
          <el-form-item v-if="!unNeed.content">
            <!-- 内部+ -->
            <el-button size="mini" type="success" @click="addContentInput">添加内容框</el-button>
            <el-button :disabled="noteForm.contentList.length === 1" size="mini" type="danger"
                       @click="delContentInput">删除内容框
            </el-button>
          </el-form-item>
          <template v-if="!unNeed.content">
            <el-card style="margin-bottom:10px" v-for="(list, index) in noteForm.contentList" :key="index">
              <el-form-item :rules="{ required: true, message: '编号不能为空', trigger: 'blur' }" label="编号:"
                            :prop="'contentList.' + index + '.bn'">
                <el-input @change="changeContent($event, index)"
                          v-model="noteForm.contentList[index].bn"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="内容:"
                            :prop="'contentList.' + index + '.content'">
                <el-input type="textarea" v-model="noteForm.contentList[index].content"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '条文属性不能为空', trigger: 'change' }"
                            label="条文属性:" :prop="'contentList.' + index + '.typeId'">
                <el-select type="textarea" v-model="noteForm.contentList[index].typeId" clearable>
                  <el-option v-for="item in typeOptions" :key="item.id" :value="item.id"
                             :label="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-card>
          </template>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addNoteDiaClose">取消</el-button>
        <el-button type="primary" @click="saveNote" :loading="saveShowLoading">保存</el-button>
      </div>
    </el-dialog>
    <!-- 查看图片 -->
    <el-dialog :visible.sync="dialog.reviewPic.show">
      <img width="100%" :src="dialog.reviewPic.dialogImageUrl" alt="">
    </el-dialog>
    <!-- 条文预览 -->
    <el-dialog :visible.sync="dialog.review.show" title="查看条文内容" v-model="dialog.review.show"
               :close-on-click-modal="false" width='50%'>
      <div class="content">
        <!-- 条文预览 结构
            条 款 standardItem clause
            条的注 standardItemNote
            款的注 clauseNote
            项 item
            项的注 itemNote
            目 list
            目的注 listNote
        -->
        <!-- 条 -->
        <template v-if="standardTreedata.length > 0">
          {{ standardTreedata[0].bn }}
          <!-- 款 clause -->
          <span v-for="(clause, i) in standardTreedata[0].children" :key="i">
                        <span :style="{ fontWeight: clause.typeId === 5 ? 'bold' : '' }">
                            {{ clause.content }}
                          <br>
                        </span>
            <!-- 条的注 standardItemNote -->
                        <div class="note-content" v-for="standardItemNote in standardTreedata[0].noteList"
                             :key="standardItemNote.id">
                            <div style="text-indent:0">表 {{ standardItemNote.bn }}{{ standardItemNote.title }}</div>
                            <div class="picList" v-if="standardItemNote.picList.length > 0">
                                <img @click="handlePictureCardPreview(pic)"
                                     v-for="(pic, picindex) in standardItemNote.picList" :key="picindex" :src="pic"
                                     style="width:120px;height:80px"/>
                            </div>
                            <div class="content"
                                 v-if="standardItemNote.content[0] !== '' && standardItemNote.content[0].bn !== '' && standardItemNote.content[0].bn !== null">
                                <span>注：</span>
                                <div>
                                    <div v-for="(content, contentIndex) in standardItemNote.content" :key="contentIndex">
                                            {{
                                        standardItemNote.content.length > 1 ? (content.bn + ' ' + content.content) : content.content
                                      }}
                                      <!-- {{ content.bn + content.content }} -->
                                    </div>
                                </div>
                            </div>
                        </div>
            <!-- 款的注 clauseNote -->
                        <div class="note-content" v-for="clauseNote in clause.noteList" :key="clauseNote.id">
                            <div style="text-indent:0">表 {{ clauseNote.bn }}{{ clauseNote.title }}</div>
                            <div class="picList" v-if="clauseNote.picList.length > 0">
                                <img @click="handlePictureCardPreview(pic)" v-for="(pic, picindex) in clauseNote.picList"
                                     :key="picindex" :src="pic" style="width:120px;height:80px"/>
                            </div>
                            <div class="content"
                                 v-if="clauseNote.content[0] !== '' && clauseNote.content[0].bn !== '' && clauseNote.content[0].bn !== null">
                                <span>注：</span>
                                <div>
                                    <div v-for="(content, contentIndex) in clauseNote.content" :key="contentIndex">
                                        {{
                                        clauseNote.content.length > 1 ? (content.bn + ' ' + content.content) : content.content
                                      }}
                                      <!-- {{ content.bn + content.content }} -->
                                    </div>
                                </div>
                            </div>
                        </div>

            <!-- 项 item -->
                        <div class="paragraph" v-for="(item, j) in clause.children" :key="j">
                            <div>
                                <span :style="{ fontWeight: item.typeId === 5 ? 'bold' : '' }">
                                    {{ item.bn + ' ' + item.content }}
                                </span>
                            </div>
                          <!-- 项的注 itemNote -->
                            <div class="note-content" v-for="itemNote in item.noteList" :key="itemNote.id">
                                <div style="text-indent:0">表 {{ itemNote.bn }}{{ itemNote.title }}</div>
                                <div class="picList" v-if="itemNote.picList.length > 0">
                                    <img @click="handlePictureCardPreview(pic)"
                                         v-for="(pic, picindex) in itemNote.picList" :key="picindex" :src="pic"
                                         style="width:120px;height:80px"/>
                                </div>
                                <div class="content"
                                     v-if="itemNote.content[0] !== '' && itemNote.content[0].bn !== '' && itemNote.content[0].bn !== null">
                                    <span>注：</span>
                                    <div>
                                        <div v-for="(content, contentIndex) in itemNote.content" :key="contentIndex">
                                            {{
                                            itemNote.content.length > 1 ? (content.bn + ' ' + content.content) : content.content
                                          }}
                                          <!-- {{ content.bn + content.content }} -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                          <!-- 目 list -->
                            <div v-for="(list, k) in item.children" :key="k">
                                <div :style="{ fontWeight: list.typeId === 5 ? 'bold' : '', paddingLeft: '20px' }">
                                    {{ list.bn + ' ' + list.content }}
                                </div>
                              <!-- 目的注 listNote -->
                                <div class="note-content" v-for="listNote in list.noteList" :key="listNote.id">
                                    <div style="text-indent:0">表 {{ listNote.bn }}{{ listNote.title }}</div>
                                    <div class="picList" v-if="listNote.picList.length > 0">
                                        <img @click="handlePictureCardPreview(pic)"
                                             v-for="(pic, picindex) in listNote.picList" :key="picindex" :src="pic"
                                             style="width:120px;height:80px"/>
                                    </div>
                                    <div class="content"
                                         v-if="listNote.content[0] !== '' && listNote.content[0].bn !== '' && listNote.content[0].bn !== null">
                                        <span>注：</span>
                                        <div>
                                            <div v-for="(content, contentIndex) in listNote.content" :key="contentIndex">
                                                {{
                                                listNote.content.length > 1 ? (content.bn + ' ' + content.content) : content.content
                                              }}
                                              <!-- {{ content.bn + content.content }} -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
        </template>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialog.review.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 1111111111111111111 添加弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="addDialogShow" v-model="addDialogShow"
               :close-on-click-modal="false" :show-close="false" width='50%'>
      <el-form :model="addFormMore" :rules="addRulesMore" ref="addFormRefMore" label-width="160px"
               style='min-width:800px'>
        <!-- <el-form-item label="问题编号:" prop="questionNumber">
            <el-input v-model="addFormMore.questionNumber"></el-input>
        </el-form-item> -->
        <el-form-item label="问题描述:" prop="questionDescription">
          <el-input style="width:80%" type="textarea" v-model="addFormMore.questionDescription"
                    :rows="4"></el-input>
        </el-form-item>
        <el-form-item label="所属规范:" prop="drStandardId">
          <div style="border: 1px solid lightgray; padding: 0px 5px;border-radius: 5px;width: 78%">
            {{ this.unNeedData.standardName + ' ' + this.unNeedData.standardBn }}
          </div>
        </el-form-item>
        <el-form-item label="所属条文:" prop="drStandardItemArr">
          <el-tooltip class="tooltip" effect="dark" :content="tooltipContent" placement="bottom">
            <el-cascader clearable style="width:80%" @mouseenter.native="showStandardItem"
                         :show-all-levels="false" v-model="addFormMore.drStandardItemArr" @change="changeStandardItem"
                         :options="standardItemOptions"
                         :props="{ value: 'id', label: 'bn', children: 'children', checkStrictly: true }">
              <template slot-scope="{ node, data }">
                <el-tooltip class="tooltip" effect="dark"
                            :content="node.level === 1 ? data.bn : data.content" placement="bottom">
                  <span v-if="node">{{ data.bn }}</span>
                </el-tooltip>
              </template>
            </el-cascader>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="条文关联注:">
          <el-tooltip class="tooltip" effect="dark" :content="noteTooltip" placement="bottom">
            <el-cascader clearable style="width:80%" @mouseenter.native="showNote" :show-all-levels="false"
                         v-model="addFormMore.notesArr" @change="changeStandardItemNotes" :options="notesOptions"
                         :props="{ value: 'id', label: 'bn', children: 'children', checkStrictly: true }">
              <template slot-scope="{ node, data }">
                <el-tooltip class="tooltip" effect="dark"
                            :content="node.level === 1 ? data.title : data.content" placement="bottom">
                  <span v-if="node">{{ data.bn }}</span>
                </el-tooltip>
              </template>
            </el-cascader>
          </el-tooltip>
        </el-form-item>
        <!-- <el-form-item label="审查内容:" prop="drAuditContentArr">
            <el-select @change="changeAudit" filterable style="width:80%" v-model="addFormMore.drAuditContentArr" multiple>
                <el-option v-for="item in auditContentIdOptions" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item label="问题属性:" prop="ddIdQuestionPropertie">
          <el-select v-model="addFormMore.ddIdQuestionPropertie">
            <el-option v-for="item in propertyOptions" :key="item.id" :label="item.value" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题分类:" prop="ddIdQuestionType">
          <el-select v-model="addFormMore.ddIdQuestionType">
            <el-option v-for="item in typeOptionsMore" :key="item.id" :label="item.value"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务类型:">
          <template>
            <el-checkbox-group v-model="addFormMore.ddIdNodeBizType">
              <el-checkbox :label="1">审查</el-checkbox>
              <el-checkbox :label="10">验收</el-checkbox>
              <el-checkbox :label="100">开业检查</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogCloseMore">取消</el-button>
        <el-button type="primary" @click="addSubmit" :loading="addLoading">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from '../../../util/date'
import Toolbar from '../../components/Toolbar'
import { getButtonList } from '../../promissionRouter'
import {
  getDrStandardItemList,
  getByNamespace,
  addDrStandardItemRule,
  getAllDrStandardList,
  addDrStandardItem,
  updateDrStandardItem,
  deleteDrStandardItem,
  getDrStandardItemById,
  getDrStandardItemNotesList,
  saveDrStandardItemNotes,
  deleteDrStandardItemNotes,
  uploadImagesUrl,
  getAllStandardItemNotesByItemRootId,
  addQuestion,
  updateQuestion,
  getAuditContentAllList,
  getQuestionPageList,
  getQuestionById
} from '@/api/api'

export default {
  components: { Toolbar },
  data () {
    var ruleBnValidator = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入编号'))
      } else {
        let reg = new RegExp(/^[\d\.\-\(\),、（），aAbBcCdDeEfF]*$/)
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('编号只能输入数字和.-(),、（），等字符'))
        }
      }
    }
    return {
      children1: {},
      children2: {},
      getListArray: [],
      ids: [],
      dialogTitle: '',
      // 新建
      addDialogShow: false,
      addFormMore: {
        id: undefined,
        // questionNumber: '',
        questionDescription: '',
        drStandardId: undefined,
        drStandardItemId: undefined,
        drStandardItemListId: undefined,
        drStandardItemNotesId: undefined,
        notesId: undefined,
        drAuditContentListId: undefined,
        ddIdQuestionPropertie: undefined,
        ddIdQuestionType: undefined,
        drStandardItemArr: [],
        // drAuditContentArr: [],
        notesArr: [],
        ddIdNodeBizType:[],
      },
	  addDrStandardId: undefined,
      noteTooltip: '',
      notesOptions: [], //注
      addRulesMore: {
        // questionNumber: [{ required: true, message: '请输入问题编号', trigger: 'blur' }],
        questionDescription: [{ required: true, message: '请输入问题描述', trigger: 'blur' }],
        // drStandardId: [{ required: true, message: '请选择所属规范', trigger: 'change' }],
        drStandardItemArr: [{ required: true, message: '请选择所属条文', trigger: 'change' }],
        // drAuditContentArr: [{ required: true, message: '请选择审核对象', type: 'array', trigger: 'change' }],
        ddIdQuestionPropertie: [{ required: true, message: '请选择问题属性', trigger: 'change' }],
        ddIdQuestionType: [{ required: true, message: '请选择问题分类', trigger: 'change' }],
      },
      propertyOptions: [], //问题属性
      addLoading: false,
      targetObject: [],
      tooltipContent: '',
      standardItemOptions: [], //条文
      tableDataQuery:[],


      // 界限
      utils: '',
      buttonList: [],
      unNeedData: '',
      filters: {
        keyword: '',
        standardId: null
      },

      filteredStandardOptions: [],// 过滤后的规范选项数据
      standardTreedata: [],
      step: 1,
      tableData: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      // 弹窗相关变量
      dialog: {
        add: {
          show: false,
          title: '',
          editing: false,
          loading: false,
        },
        rule: {
          show: false,
          title: '',
        },
        note: {
          show: false,
          title: ''
        },
        addNote: {
          show: false,
          showLoading: false,
          isEdit: false
        },
        review: {
          show: false
        },
        reviewPic: {
          show: false,
          dialogImageUrl: ''
        }
      },
      saveShowLoading: false,
      checkedTop: true,
	    isAi:false,
      // 新增条文form  新增条文规则form
      addForm: {
        id: undefined,
        bn: '',
        standardId: undefined,
      },
      form: {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
        picList:undefined,
	      isAi: undefined,
      },
      noteForm: {
        bn: '',
        title: '',
        picList: '',
        contentList: [{ bn: '', content: '', typeId: undefined }]
      },
      addRules: {
        bn: [{
          required: true,
          message: '编号只能输入数字和.-(),、（），等字符',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        standardId: [{ required: true, message: '请选择所属规范', trigger: 'change' }],
      },
      rules: {
        bn: [{
          required: true,
          message: '编号只能输入数字和.-(),、（），等字符',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        content: [{ required: true, message: '请输入条文内容', trigger: 'blur' }],
        typeId: [{ required: true, message: '请选择类型', trigger: 'change' }],
      },
      noteRules: {
        bn: [{
          required: true,
          message: '编号只能输入数字和.-(),、（），等字符',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        // picList: [{ required: true, message: '请上传图片', trigger: 'change' }],
      },
      // 当前条文id
      standardItemId: undefined,
      // options
      standardOptions: [], //所属规范
      typeOptions: [], //条文类型
      typeOptionsMore: [], //条文类型
      // 注
      unNeed: {
        pic: false,
        content: false
      },
      notesList: [],
      allNotesList: [],
      actions: '',
      fileList: [],
      imageUrl: '',
      checkOption:[],
      checkOptionObject:[]
    }
  },
	watch: {
		'isAi' (val) {
			this.form.isAi = val
		},
	},
  computed: {
    // 上传文件请求头token
    headers () {
      return {
        'Authorization': 'Bearer ' + localStorage.getItem('Token')
      }
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : []
    this.buttonList = getButtonList(this.$route.path, routers)
    if (this.$route.query.queryid) {
      this.filters.standardId = parseInt(this.$route.query.queryid)
    }
    this.init()
  },
  methods: {
    getAuditContentFun () {
      getAuditContentAllList().then(res => {
        if (res.data.success) {
          this.auditContentIdOptions = res.data.response
	        this.addDialogShow = true
        }
      })
    },
    // 新建
    handleAddMore () {

      this.addLoading = false
      this.dialogTitle = '新增'
      this.addFormMore = {
        id: undefined,
        // questionNumber: '',
        questionDescription: '',
        drStandardId: undefined,
        drStandardItemId: undefined,
        drStandardItemListId: undefined,
        drStandardItemNotesId: undefined,
        notesId: undefined,
        drAuditContentListId: undefined,
        ddIdQuestionPropertie: this.addFormMore.ddIdQuestionPropertie?this.addFormMore.ddIdQuestionPropertie:undefined,
        ddIdQuestionType: this.addFormMore.ddIdQuestionType?this.addFormMore.ddIdQuestionType:undefined,
        drStandardItemArr: [],
        ddIdNodeBizType:[],
        // drAuditContentArr: []
        notesArr: [],
      }
      this.getAuditContentFun()

    },
    // 新增弹窗关闭
    addDialogCloseMore () {
      this.addDialogShow = false
      this.targetObject = []
      this.ids=[]
      this.checkOptionObject =[]
      this.checkOption=[]
      this.addFormMore = {
        id: undefined,
        // questionNumber: '',
        questionDescription: '',
        drStandardId: undefined,
        drStandardItemId: undefined,
        drStandardItemListId: undefined,
        drStandardItemNotesId: undefined,
        notesId: undefined,
        drAuditContentListId: undefined,
        ddIdQuestionPropertie: this.addFormMore.ddIdQuestionPropertie,
        ddIdQuestionType: this.addFormMore.ddIdQuestionType,
        drStandardItemArr: [],
        // drAuditContentArr: []
        notesArr: [],
        ddIdNodeBizType: [],
      }
      this.getDataList()
    },
    //数组求和
    arraySum (arr) {
      return arr.reduce((sum, num) => sum + num, 0);
    },
    // 新建提交
    addSubmit () {
      this.$refs.addFormRefMore.validate((valid) => {
        if (valid) {
          this.addLoading = true
          const item = JSON.parse(this.addFormMore.drStandardItemListId)
          console.log(item,'item')
          if (item&&item.length>4){
            this.$message({
              message: '数据错误，请刷新后再试',
              type: "error"
            });
            return
          }
            let params = {
              ...this.addFormMore,
            }
            if(this.addFormMore.ddIdNodeBizType&&this.addFormMore.ddIdNodeBizType.length>1){
              params.ddIdNodeBizType= this.arraySum(this.addFormMore.ddIdNodeBizType)
            }
            if (this.addFormMore.ddIdNodeBizType && this.addFormMore.ddIdNodeBizType.length === 1) {
              params.ddIdNodeBizType = this.addFormMore.ddIdNodeBizType[0]
            }
            addQuestion(params).then(res => {
              if (res.data.success) {
                this.$message({
                  message: res.data.message,
                  type: 'success'
                })
                //this.getDataList()
                this.addDialogCloseMore()
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error'
                })
              }
              this.addLoading = false
            })
        }
      })
    },
    // 级联查看注
    showNote () {
      if (!this.addFormMore.drStandardItemNotesId) {
        this.noteTooltip = ''
        return
      } else {
        let list = JSON.parse(this.addFormMore.drStandardItemNotesId)
        if (list.content === '') {
          this.noteTooltip = list.bn
        } else if (!list) {
          this.noteTooltip = ''
        } else {
          this.noteTooltip = list.children?.[0].bn + ' ' + list.children?.[0].content
        }
      }
    },
    // 选择注
    changeStandardItemNotes (data) {
      if (data && data.length > 0) {
        const list = this.treeToList(this.notesOptions)
        let resParent = list.filter(item => {
          return Number(data[0]) === Number(item.id)
        })
        let obj = {
          ...resParent[0]
        }
        if (data.length > 1) {
          let res = list.filter(item => {
            return data[1] === item.id
          })
          obj.children = res
        }

        this.addFormMore.drStandardItemNotesId = JSON.stringify(obj)
      } else {
        this.addFormMore.drStandardItemNotesId = undefined
      }

    },
    treeToList (list) {
      console.log(list,'list')
      let res = []
      for (const item of list) {
        const { children, ...i } = item
        if (children && children.length) {
          res = res.concat(this.treeToList(children))
        }
        res.push(i)
      }
      return res
    },
    //默认显示所属条文的层级
    getIdRecursive (obj) {
      if (obj){
        // console.log(obj,'obj1')
        // this.treeToList(obj)
        this.ids = [+obj.content[0].id]
        if (obj.content[0].children[0]){
          this.ids.push(+obj.content[0].children[0].id)
        }
        if (obj.content[0].children[0].children[0]) {
          this.ids.push(+obj.content[0].children[0].children[0].id)
        }
        if(obj.content[0].children[0].children[0]&&obj.content[0].children[0].children[0].children[0]){
          this.ids.push(+obj.content[0].children[0].children[0].children[0].id)
        }
      }
    },
    getListArratId () {
      const targetObject = this.getListArray.find(item => item.id === this.unNeedData.id)
      const jsonTarget = targetObject.content
      const parseTarget = JSON.parse(jsonTarget)
      // console.log(parseTarget,'parse')
      this.targetObject.push(targetObject)

      const children1 = parseTarget[0].children? parseTarget[0].children.filter(item=>item.id===this.ids[1]):[]
      // console.log(children1, 'parse1')
      if (parseTarget[0].children){
        let param1 = {
          id: children1[0].id,
          bn: children1[0].bn,
          content: children1[0].content,
          typeId: children1[0].typeId,
          parentId: children1[0].parentId,
          standardId: children1[0]?.standardId,
          // drStandardItemSerialNumber: children1[0]?.drStandardItemSerialNumber
        }
        this.targetObject.push( param1)
      }

      if (children1[0].children&&children1[0].children.length>0){
        const children2 = children1[0].children.filter(item=>item.id===this.ids[2])
        let param2 = {
          id: children2[0].id,
          bn: children2[0].bn,
          content: children2[0].content,
          typeId: children2[0].typeId,
          parentId: children2[0].parentId,
          standardId: children2[0]?.standardId,
          // drStandardItemSerialNumber: children1[0]?.drStandardItemSerialNumber
        }
        this.targetObject.push( param2)
      }
      if (children1[0].children&&children1[0].children[0]&&children1[0].children[0].children && children1[0].children[0].children.length > 0) {
        const children3 = children1[0].children[0].children.filter(item => item.id === this.ids[3])
        let param2 = {
          id: children3[0].id,
          bn: children3[0].bn,
          content: children3[0].content,
          typeId: children3[0].typeId,
          parentId: children3[0].parentId,
          standardId: children3[0]?.standardId,
          // drStandardItemSerialNumber: children1[0]?.drStandardItemSerialNumber
        }
        this.targetObject.push(param2)
      }
      this.addFormMore.drStandardItemListId = JSON.stringify(this.targetObject)

    },
    // 级联查看条文内容
    showStandardItem () {
      if (!this.addForm.drStandardItemListId) {
        return
      } else {
        let list = JSON.parse(this.addForm.drStandardItemListId);
        console.log(list ,'级联')
        let obj = list[list.length - 1];
        if (obj && obj.parentId === 0) {
          this.tooltipContent = obj?.bn;
        } else if (obj) {
          this.tooltipContent = obj?.bn + ' ' + obj?.content;
        } else {
          this.tooltipContent = '';
        }
      }

    },
    // 选择条文子项
    changeStandardItem (data) {

      this.checkOption = data
      if (!data) return
      // 获取当前选择的条文关联注
      this.getNotesMore(data[data.length - 1]);
      // 条文id
      // console.log(data,'data')
      this.addForm.drStandardItemId = undefined;
      this.addForm.drStandardItemId = data[0];

      let list = []
      // 树形结构扁平化
      const treeList = this.treeToList(this.standardItemOptions)
      for (let i = 0; i < data.length; i++) {
        // 筛选选中条文id的数据
        let obj = treeList.filter(item => {
          return item.id === data[i]
        })
        // console.log(obj,'obj')
        let formobj = {
          id: obj[0].id,
          bn: obj[0].bn,
          content: obj[0].content,
          typeId: obj[0]?.typeId,
          parentId: obj[0].parentId,
          standardId: obj[0]?.standardId,
          // drStandardItemSerialNumber:obj[0]?.drStandardItemSerialNumber
        }
        list.push(formobj)
      }
      // console.log(list,'list')
      this.checkOptionObject.push(...list);
      // console.log(this.checkOptionObject,'object')
      this.addFormMore.drStandardItemListId = undefined
      this.addFormMore.drStandardItemListId = JSON.stringify(this.checkOptionObject);
      this.checkOptionObject = undefined
    },
    // 选择规范
    async  changeStandard  (data) {
      const res = await getDrStandardItemList({
        pageSize: 1000,
        pageIndex: 1,
        standardId: data
      })
        if (res.data.success) {
          this.standardItemOptions = res.data.response.data;
          console.log(this.standardItemOptions,'规范')
          this.standardItemOptions.forEach((item, index) => {
            let obj = JSON.parse(item.content);
            item.children = obj[0].children;
            item.disabled = true
          })
          this.removeChildren(this.standardItemOptions);
        }

    },
    removeChildren (list) {
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.removeChildren(item.children)
        } else if (item.children.length === 0) {
          item.children = undefined;
        } else {
          item.children = undefined;
        }
      })
    },
    getNotesMore (id) {
      getDrStandardItemNotesList({ standardItemId: id }).then(res => {
        if (res.data.success) {
          let list = [];
          res.data.response && res.data.response.forEach(item => {
            let obj = {
              bn: item.bn,
              title: item.title,
              id: item.id,
              picList: item.picList,
              drStandardItemId: item.drStandardItemId
            }
            if (item.content !== '') {
              obj.children = JSON.parse(item.content)
            } else {
              obj.content = ''
              obj.children = []
            }
            list.push(obj);
          })
          this.notesOptions = list;
          // console.log(this.notesOptions);
        }
      })
    },






    // 界限
    callFunction (item) {
      if (item.func == 'getDataList') {
        this.pages.pageIndex = 1
      }
      this[item.func].apply(this, item)
    },
    // 初始化获取option
    init () {
      this.actions = uploadImagesUrl
      this.getDataList()
      getByNamespace({ namespace: 'BizService.DrAuditRep.QuestionPropertie' }).then(res => {
        if (res.data.success) {
          this.propertyOptions = res.data.response
        }
      })
      getByNamespace({ namespace: 'BizService.DrAuditRep.QuestionType' }).then(res => {
        if (res.data.success) {
          this.typeOptionsMore = res.data.response
        }
      })

      // 获取类型
      getByNamespace({ namespace: 'BizService.DrAuditRep.StandardItemType' }).then(res => {
        if (res.data.success) {
          this.typeOptions = res.data.response
        }
      })
      // 获取所有规范
      getAllDrStandardList().then(res => {
        if (res.data.success) {
          let options = []
          res.data.response && res.data.response.forEach(item => {
            let obj = {
              id: item.id,
              name: `${item.name} ${item.bn}(${item.version})`
            }
            options.push(obj)
          })
          this.standardOptions = options
          this.filteredStandardOptions = this.standardOptions
        }
      })
    },
    // 列表查询接口
    getDataList () {
      let params = {
        keyword: this.filters.keyword,
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
        standardId: this.filters.standardId,
	      sourceType:'web',
      }
      this.listLoading = true
      getDrStandardItemList(params).then(res => {
        if (res.data.success) {
          let list = []
          this.getListArray = res.data.response.data
          res.data.response.data && res.data.response.data.forEach(item => {
            let content = JSON.parse(item.content)
            let obj = {
              ...item,
              content: content
            }
            list.push(obj)
          })
          this.tableData = list
          // console.log(this.tableData);
          this.pages.dataCount = res.data.response.dataCount
          this.listLoading = false
        }
      })
    },
    // 新建条文
    handleAdd () {
      this.step = 1
      this.dialog.add.show = true
      this.dialog.add.loading = false
      this.dialog.add.title = '新增条文编号'
      this.addForm = {
        id: undefined,
        bn: '',
        standardId: this.addDrStandardId,
      }
      this.form = {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
      }
    },
    // 获取条文树形结构
    async getTree () {
      await this.getAllNotes()
      let res = await getDrStandardItemById({ id: this.standardItemId })
      if (res.data.success) {
        let tree = JSON.parse(res.data.response.content)
        // console.log(tree,'tree')
        if (tree && tree.length > 0) {
          var newTree = tree[0].children
          if (newTree && newTree.length > 0) {
            var sortTree = newTree[0].children
            sortTree.sort(function (a, b) {
              var key = 'bn'
              return parseInt(a[key]) - parseInt(b[key])
            })
          }
        }
        this.standardTreedata = tree
        console.log(tree,'tree')
        await this.treeDataAddNote(this.standardTreedata)
      }
      // console.log(this.standardTreedata);
    },
    // 获取所有注
    async getAllNotes () {
      let notesRes = await getAllStandardItemNotesByItemRootId({ itemRootId: this.standardItemId })
      if (notesRes.data.success) {
        let list = []
        notesRes.data.response && notesRes.data.response.forEach(item => {
          let pic = []
          if (item.picList !== '') {
            pic = item.picList.split(',')
          }
          let obj = {
            bn: item.bn,
            title: item.title,
            id: item.id,
            drStandardItemId: item.drStandardItemId,
            picList: pic
          }
          let str = item.content
          if (str.indexOf('[') === -1) {
            // 不为json字符串
            obj.content = [str]
          } else {
            obj.content = JSON.parse(str)
          }
          list.push(obj)
        })
        this.allNotesList = list
      }
    },
    // 递归给树的每个子节点加上注
    treeDataAddNote (list) {
      list.forEach((i, index) => {
        if (i.children) {
          let data = this.allNotesList.filter(item => {
            return item.drStandardItemId === i.id
          })
          list[index].noteList = data
          this.treeDataAddNote(i.children)
        } else {
          let data = this.allNotesList.filter(item => {
            return item.drStandardItemId === i.id
          })
          list[index].noteList = data
        }
      })
    },
    // 预览条文内容
    async onReview (id) {
      this.standardItemId = id
      await this.getTree()
      this.dialog.review.show = true
    },
    // 查看条文规则
    nextStep () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          if (this.addForm.id === undefined) {
			  this.addDrStandardId = this.addForm.standardId;
            addDrStandardItem(this.addForm).then(res => {
              if (res.data.success) {
                this.standardTreedata = []
                this.step += 1
                this.dialog.add.title = '新增条文内容'
                this.standardItemId = res.data.message
                this.getTree()
                this.getDataList()
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error'
                })
              }
            })
          } else {
            // 编辑条文
            let params = {
              content: '',
              ...this.addForm
            }
            updateDrStandardItem(params).then(res => {
              if (res.data.success) {
                this.$message({
                  message: res.data.message,
                  type: 'success'
                })
                this.addDialogClose()
                // this.getDataList()
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error'
                })
              }
            })
          }

        }
      })
    },
    // 点击子节点
    checkNode (node, data) {
      if (node.level !== 1) {
        this.checkedTop = false
        console.log(data,'data')
        getDrStandardItemById({ id: data.id }).then(res => {
          if (res.data.success) {
            this.form = {
              id: data.id,
              bn: res.data.response.bn,
              content: res.data.response.content,
              parentId: res.data.response.parentId,
              typeId: res.data.response.typeId,
              standardId: res.data.response.standardId,
	            isAi: res.data.response.isAi
            }
          }
        })
      } else {
        // 条文级没有内容
        this.checkedTop = true
        this.form = {
          id: data.id,
          bn: '',
          content: '',
          parentId: 0,
          typeId: undefined,
          standardId: undefined,
	        isAi: undefined,
        }
      }
    },
    // 新增子节点
    addChild (node, data) {
      this.dialog.rule = {
        show: true,
        title: '新增子节点'
      }
      this.form = {
        parentId: data.id,
        id: undefined,
        bn: '',
        content: '',
        typeId: undefined,
	      isAi:undefined,
      }
    },
    //新增子节点 提交
    addRuleSubmit () {
      let params = {
        bn: this.form.bn,
        content: this.form.content,
        parentId: this.form.parentId,
        typeId: this.form.typeId,
	      isAi:this.form.isAi,
      }
      addDrStandardItemRule(params).then(res => {
        if (res.data.success) {
          this.getTree()
          this.ruleDialogClose()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },
    // 保存子节点
    saveRuleSubmit () {
      updateDrStandardItem(this.form).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.getTree()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },
    // 新增注的内容框
    addContentInput () {
      this.noteForm.contentList.push({ bn: '', content: '', typeId: undefined })
    },
    // 删除最后一个内容框
    delContentInput () {
      this.noteForm.contentList.pop()
    },
    // 输入时判断content中bn是否有重复
    changeContent (e, index) {
      this.noteForm.contentList.forEach((item, i) => {
        if (item.bn === e && i !== index) {
          this.$message({
            message: '编号不能重复',
            type: 'error'
          })
        }
      })
    },
    // 保存注
    saveNote () {
      this.$refs.noteRef.validate((valid) => {
        if (valid) {
          let params = {
            bn: this.noteForm.bn,
            title: this.noteForm.title,
            drStandardItemId: this.standardItemId
          }
          // 内容数组转json
          if (this.noteForm.contentList.length > 0) {
            if (this.noteForm.contentList[0].bn !== '') {
              this.noteForm.contentList.forEach(item => {
                item.id = item.bn
              })
              params.content = JSON.stringify(this.noteForm.contentList)
            } else {
              params.content = ''
            }
          } else {
            params.content = ''
          }

          // 图片数组转json
          // gws
          if (this.fileList.length > 0) {
            let url = ''
            this.fileList.forEach(file => {
              url = url + file.response.response.weburl + ','
            })
            params.picList = url.substring(0, url.length - 1)
          }
          // 不需要图片
          if (this.unNeed.pic) {
            params.bn = ''
            params.title = ''
            params.picList = ''
          }
          // 不需要注
          if (this.unNeed.content) {
            params.content = ''
          }

          // 判断图注编号是否重复 不需要判断 0206 林
          // if (params.bn !== '') {
          //     this.notesList.forEach(item => {
          //         if (item.bn === params.bn) {
          //             this.$message({
          //                 type: 'error',
          //                 message: '编号不能重复'
          //             })
          //             return
          //         }
          //     })
          // }

          // 判断contentList是否重复

          if (this.noteForm.contentList.length > 0 && this.noteForm.contentList[0].bn !== '') {
            let arr = this.noteForm.contentList.map(value => value.bn)
            let setArr = new Set(arr)
            // 因为Set会自动去重 所以和原数组长度不同
            if (setArr.size !== arr.length) {
              this.$message.error('内容编号不能重复！')
              return
            }
          }

          if (this.noteForm.id) {
            params.id = this.noteForm.id
          }
          this.saveShowLoading = true
          saveDrStandardItemNotes(params).then(res => {
            if (res.data.success) {
              this.getNotes()
              this.addNoteDiaClose()
            } else {
              this.$message({
                message: res.data.message,
                type: 'error'
              })
            }
            this.saveShowLoading = false
          })
        }
      })
      this.getDataList()
    },
    // 删除子节点
    delItem (node, data) {
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        deleteDrStandardItem({ id: data.id }).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getTree()
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
      })
    },
    // 弹窗关闭
    addDialogClose () {
      this.getDataList()
      this.dialog.add.show = false
      this.dialog.add.editing = false
      this.checkedTop = true
      this.addForm = {
        id: undefined,
        bn: '',
        standardId: undefined,
      }
      this.form = {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
      }
    },
    ruleDialogClose () {
      this.dialog.rule.show = false
      this.form = {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
      }
    },
    noteDialogClose () {
      this.dialog.note.show = false
    },
    addNoteDiaClose () {
      this.dialog.addNote.show = false
      this.dialog.addNote.isEdit = false
      this.noteForm = {
        bn: '',
        title: '',
        picList: '',
        contentList: [{ bn: '', content: '', typeId: undefined }]
      }
      this.fileList = []
    },
    // 编辑条文
    handleEdit () {
      if (!this.unNeedData.id) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      this.step = 2
      this.dialog.add = {
        editing: true,
        show: true,
        loading: false,
        title: '编辑条文'
      }
      getDrStandardItemById({ id: this.unNeedData.id }).then(res => {
        if (res.data.success) {
          this.standardItemId = this.unNeedData.id
          this.addForm.id = res.data.response.id
          this.addForm.bn = res.data.response.bn
          this.addForm.standardId = res.data.response.standardId
	        this.isAi = res.data.response.isAi
          let tree = JSON.parse(res.data.response.content)
          this.standardTreedata = tree
        }
      })
    },
    // 删除条文
    handleDel () {
      if (!this.unNeedData.id) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        deleteDrStandardItem({ id: this.unNeedData.id }).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getDataList()
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
      })
    },
    // 查看关联注
    onNotes () {
      if (!this.unNeedData.id) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      this.standardItemId = this.unNeedData.id
      this.getTree()
      this.getNotes()
      this.dialog.note.show = true
      this.dialog.note.title = this.unNeedData.bn
    },
    // 查看关联注
    onNotesMore (node,data) {
      console.log(data,'data3333')
      this.standardItemId = data.id
      // this.getTree()
      this.getNotesM(data.id)
      this.dialog.note.show = true
      this.dialog.note.title = data.bn
    },
    // 查看注
    checkNote (data, node) {
      if (node.level === 1) {
        this.dialog.note.title = node.data.bn
      } else {
        this.dialog.note.title = node.data.bn + node.data.content
      }

      this.standardItemId = node.data.id
      this.getNotes()
    },
    getNotesM (id) {
      getDrStandardItemNotesList({ standardItemId:id}).then(res => {
        if (res.data.success) {
          let list = []
          res.data.response && res.data.response.forEach(item => {
            let obj = {
              bn: item.bn,
              title: item.title,
              id: item.id
            }
            if (item.content !== '') {
              obj.content = JSON.parse(item.content)
            }
            if (item.picList !== '') {
              obj.picList = item.picList.split(',')
            }
            list.push(obj)
          })
          this.notesList = list
        }
      })
    },
    // 获取注列表
    getNotes () {
      getDrStandardItemNotesList({ standardItemId: this.standardItemId }).then(res => {
        if (res.data.success) {
          let list = []
          res.data.response && res.data.response.forEach(item => {
            let obj = {
              bn: item.bn,
              title: item.title,
              id: item.id
            }
            if (item.content !== '') {
              obj.content = JSON.parse(item.content)
            }
            if (item.picList !== '') {
              obj.picList = item.picList.split(',')
            }
            list.push(obj)
          })
          this.notesList = list
        }
      })
    },
    // 删除该注
    delNotes (item) {
      this.$confirm('确认删除该注?', '提示', {
        type: 'warning'
      }).then(() => {
        deleteDrStandardItemNotes({ id: item.id }).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getNotes()
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
      })
    },
    // 编辑该注
    editNote (item) {
      this.dialog.addNote.show = true
      this.dialog.addNote.isEdit = true
      this.noteForm = {
        id: item.id,
        bn: item.bn,
        title: item.title,
        picList: item.picList,
        contentList: item.content ? item.content : []
      }
      if (item.picList && item.picList.length > 0) {
        let fileList = []
        item.picList.map((pic, index) => {
          const file = {
            uid: index,
            status: 'success',
            response: {
              response: {
                weburl: pic
              }
            },
            url: pic,

          }
          fileList.push(file)

        })
        this.fileList = fileList
        // this.$refs.upload.uploadFiles = fileList;
      }
    },
    addNotes () {
      this.dialog.addNote.show = true
      this.dialog.addNote.isEdit = false
    },
    // 上传图片回调
    uploadSuccess (res, file) {
      if (res.success) {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    },
    handleChange (file, fileList) {
      this.fileList = fileList
      this.$refs.upload.clearFiles()
      this.$refs.upload.uploadFiles.push(file)
    },
    handlePictureCardPreview (file) {
      this.dialog.reviewPic.dialogImageUrl = file.url || file
      this.dialog.reviewPic.show = true
    },
    // common
    // 点击table某一行
    async currentChange (val) {
		if(val)
		{
      await this.changeStandard(val.standardId)
			this.unNeedData = val
			this.getIdRecursive(this.unNeedData)


			this.addFormMore.drStandardId = this.unNeedData.standardId
			// console.log(this.unNeedData, 11111)
			this.addFormMore.drStandardItemArr = [...this.ids]
      this.getListArratId()
			// this.addFormMore.drStandardItemListId = JSON.stringify(this.targetObject)
			if (!this.addFormMore.drStandardItemId){
        this.addFormMore.drStandardItemId = this.unNeedData.id
      }
      if (!this.checkOption.length > 0) {
        this.getNotesMore(this.ids[this.ids.length - 1])
      }
		}
    },
    // 分页
    handleCurrentChange (val) {
      this.pages.pageIndex = val
      this.getDataList()
    },
    handleSizeChange (val) {
      this.pages.pageSize = val
      this.getDataList()
    },
    getListData(){
      this.pages.pageIndex=1;
      this.getDataList()
    },
    // 时间格式化
    formatCreateTime: function (row, column) {
      return !row.NoticeTime || row.NoticeTime == ''
          ? ''
          : util.formatDate.format(new Date(row.NoticeTime), 'yyyy-MM-dd hh:mm')
    },
    // 表格数据分页条数累计
    indexMethod (index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  }
}
</script>

<style lang="stylus" scoped>
/deep/ input[aria-hidden="true"] {
	display: none !important;
}

/deep/.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
	box-shadow: none !important;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.dialog-content {
  display flex
  height 400px
  justify-content space-between
  overflow-y scroll


  .lf {
    width 30%
    padding-right 20px
  }

  .rt {
    flex 1
    border-left 1px solid #fafafa
    padding-left 20px
  }
}

.paragraph {
  text-indent 2em
}

.note-content {
  display flex
  flex-direction column
  align-items center

  .content {
    align-self: flex-start;
    display: flex
  }
}

.picList {
  display flex
  flex-direction column
  align-items center
  justify-content center

  img {
    object-fit contain
    cursom pointer
  }

}
</style>
